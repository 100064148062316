import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import dayjs from "dayjs";
import { useTheme } from "@mui/material/styles"; 

const apiUrl = process.env.REACT_APP_SERVER_URL;

const ExpensesTable = () => {
  const [expenses, setExpenses] = useState([]);

  const [editItem, setEditItem] = useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  const theme = useTheme();

  useEffect(() => {
    axios
      .get(`${apiUrl}/expense`)
      .then((response) => {
        // console.log(response.data); // Check the structure of response data
        setExpenses(response.data); // Make sure this data is an array
      })
      .catch((error) => {
        console.error("There was an error fetching the expenses!", error);
      });
  }, []);

  if (!expenses.length) return <Typography>Loading...</Typography>; // Check if array is empty

  const formatAmount = (amount) => {
    const parsedAmount = parseFloat(amount);
    return isNaN(parsedAmount)
      ? "0.00"
      : parsedAmount.toLocaleString("en-US", {
          minimumFractionDigits: 3,
          maximumFractionDigits: 3,
        });
  };

  const formatDate = (date) => {
    return dayjs(date).format("YYYY-MM-DD");
  };

  const handleDeleteExpense = (id) => {
    axios
      .delete(`${apiUrl}/expense/${id}`)
      .then(() => {
        setExpenses((prevExpenses) =>
          prevExpenses.filter((expense) => expense._id !== id)
        );
      })
      .catch((error) => {
        console.error("There was an error deleting the expense!", error);
      });
  };

  const handleEditItem = (type, item) => {
    setEditItem({ type, item });
    setEditDialogOpen(true);
  };

  const handleSaveEdit = () => {
    const { item } = editItem;

    axios
      .put(`${apiUrl}/expense/${item._id}`, {
        amount: item.amount,
        date: item.date,
        description: item.description,
      })
      .then((response) => {
        setExpenses((prevExpenses) =>
          prevExpenses.map((expense) =>
            expense._id === response.data._id ? response.data : expense
          )
        );
        setEditDialogOpen(false);
      })
      .catch((error) => {
        console.error("There was an error updating the expense!", error);
      });
  };

  return (
    <Container>
      <Typography
        variant="h4"
        gutterBottom
        fontWeight={"bold"}
        margin={2.5}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        المصاريف
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontSize: "20px", fontWeight: "bold" }}>
                المبلغ{" "}
              </TableCell>
              <TableCell sx={{ fontSize: "20px", fontWeight: "bold" }}>
                التاريخ{" "}
              </TableCell>
              <TableCell sx={{ fontSize: "20px", fontWeight: "bold" }}>
                الملاحظات
              </TableCell>
              <TableCell sx={{ fontSize: "20px", fontWeight: "bold" }}>
                {/* Actions */}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {expenses.map((expense) => (
              <TableRow key={expense._id}>
                <TableCell>{formatAmount(expense.amount)}</TableCell>
                <TableCell>{formatDate(expense.date)}</TableCell>
                <TableCell>{expense.description}</TableCell>
                <TableCell>
                  <IconButton
                    variant="contained"
                    color="primary"
                    sx={{
                      padding: "5px",
                      margin: "5px",
                      color: "#44484e",
                    }}
                    onClick={() => handleDeleteExpense(expense._id)}
                  >
                    <ClearIcon />
                  </IconButton>

                  <IconButton
                    variant="contained"
                    color="primary"
                    sx={{
                      padding: "5px",
                      margin: "5px",
                      color: "#44484e",
                    }}
                    onClick={() => handleEditItem("expense", expense)}
                  >
                    <EditIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
        <DialogTitle
          sx={{
            textAlign: "center",
            fontWeight: "bold",
            fontSize: "30px",
            color: theme.palette.mode === "dark" ? "#fff" : "#44484e",
          }}
        >
          تعديل
        </DialogTitle>
        <DialogContent>
          {editItem && (
            <Box
              sx={{ display: "flex", flexDirection: "column", margin: "5px" }}
            >
              <TextField
                label="المبلغ"
                value={editItem.item.amount}
                onChange={(e) =>
                  setEditItem({
                    ...editItem,
                    item: { ...editItem.item, amount: e.target.value },
                  })
                }
                fullWidth
                sx={{
                  marginBottom: "20px",

                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      // borderColor: "#44484e",
                      // borderColor: "#ffff",
                      color: theme.palette.mode === "dark" ? "#fff" : "#44484e",
                    },
                    "&:hover fieldset": {
                      borderColor: "#44484e",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#44484e",
                    },
                  },
                  "& .MuiInputLabel-root": {
                    // color: "#44484e",
                    // color: "#ffff",
                    color: theme.palette.mode === "dark" ? "#fff" : "#44484e",
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#44484e",
                  },
                }}
              />
              <TextField
                label="التاريخ"
                type="date"
                value={formatDate(editItem.item.date)}
                onChange={(e) =>
                  setEditItem({
                    ...editItem,
                    item: { ...editItem.item, date: e.target.value },
                  })
                }
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{
                  marginBottom: "20px",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      // borderColor: "#44484e",
                      // borderColor: "#fff",
                      color: theme.palette.mode === "dark" ? "#fff" : "#44484e",
                    },
                    "&:hover fieldset": {
                      borderColor: "#44484e",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#44484e",
                    },
                  },
                  "& .MuiInputLabel-root": {
                    // color: "#44484e",
                    // color: "#fff",
                    color: theme.palette.mode === "dark" ? "#fff" : "#44484e",
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#44484e",
                  },
                }}
              />
              <TextField
                label="الملاحظات"
                value={editItem.item.description}
                onChange={(e) =>
                  setEditItem({
                    ...editItem,
                    item: { ...editItem.item, description: e.target.value },
                  })
                }
                fullWidth
                sx={{
                  marginBottom: "20px",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      // borderColor: "#44484e",
                      // borderColor: "#ffff",
                      color: theme.palette.mode === "dark" ? "#fff" : "#44484e",
                    },
                    "&:hover fieldset": {
                      borderColor: "#44484e",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#44484e",
                    },
                  },
                  "& .MuiInputLabel-root": {
                    // color: "#44484e",
                    // color: "#fff",
                    color: theme.palette.mode === "dark" ? "#fff" : "#44484e",
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#44484e",
                  },
                }}
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setEditDialogOpen(false)}
            sx={{
              // color: "#44484e",
              // color: "#fff",
              color: theme.palette.mode === "dark" ? "#fff" : "#44484e",
              fontWeight: "bold",
            }}
          >
            الالغاء
          </Button>
          <Button
            onClick={handleSaveEdit}
            sx={{
              // color: "#44484e",
              // color: "#fff",
              color: theme.palette.mode === "dark" ? "#fff" : "#44484e",
              fontWeight: "bold",
            }}
          >
            حفظ
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default ExpensesTable;
