import React from "react";
import { Box, Typography,  } from "@mui/material";
import IncomeInventory from "./IncomeInventory";
import ExpensesInventory from "./ExpensesInventory";
import SumSalary from './SumSalary';

const MainContent = () => {
  
  return (
    <Box sx={{ padding: "20px", direction: "rtl" }}>
      <Typography
        sx={{
          textAlign: "center",
          fontSize: "35px",
          fontWeight: "bold",
          marginBottom: "10px",
        }}
      >
        جرد الدخل
      </Typography>
      <IncomeInventory />
      <Typography
        sx={{
          textAlign: "center",
          fontSize: "35px",
          fontWeight: "bold",
          marginTop: "15px",
          marginBottom: "10px",
        }}
      >
        جرد المصروف
      </Typography>
      <ExpensesInventory />
      <Typography
        sx={{
          textAlign: "center",
          fontSize: "35px",
          fontWeight: "bold",
          marginTop: "15px",
          marginBottom: "10px",
        }}
      >
        مجموع الرواتب 
      </Typography>
      <SumSalary />
    </Box>
  );
};

export default MainContent;
