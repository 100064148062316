/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

// this code show the sum of the salary of all employees

// import React, { useEffect, useState } from 'react'
// import  axios  from 'axios';
// import { Box, Typography } from '@mui/material';

// const apiUrl = process.env.REACT_APP_SERVER_URL;

// const SumSalary = () => {
//   const [employee, setEmployee] = useState([]);

//   useEffect(() => {
//     getAllEmployees();
//   }, []);

//   //get all products
//   const getAllEmployees = async () => {
//     try {
//       const { data } = await axios.get(`${apiUrl}/employee`);
//       setEmployee(data.employees);
//     } catch (error) {
//       console.error("Error fetching Employee:", error);
//     }
//   };

//   const formatAmount = (amount) => {
//     const parsedAmount = parseFloat(amount);
//     return isNaN(parsedAmount)
//       ? "0.00"
//       : parsedAmount.toLocaleString("en-US", {
//           minimumFractionDigits: 3,
//           maximumFractionDigits: 3,
//         });
//   };
//   // Calculate the sum of all salaries
//   const sumOfSalaries = employee.reduce(
//     (total, emp) => total + (parseFloat(emp.salary) || 0),
//     0
//   );

//   return (
//     <Box>
//       <Typography variant="h6">Sum of Salaries</Typography>
//       <Typography variant="body1">{formatAmount(sumOfSalaries)}</Typography>
//     </Box>
//   );
// }

// export default SumSalary

// this code show the start and end of the week and the sum of salary of the employees in this week

// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import {
//   Box,
//   Typography,
//   Table,
//   TableBody,
//   TableCell,
//   TableHead,
//   TableRow,
// } from "@mui/material";
// import dayjs from "dayjs";
// import isoWeek from "dayjs/plugin/isoWeek";

// dayjs.extend(isoWeek); // Enable week-based functionality

// const apiUrl = process.env.REACT_APP_SERVER_URL;

// const SumSalary = () => {
//   const [employee, setEmployee] = useState([]);
//   const [weeklySalaries, setWeeklySalaries] = useState([]);

//   useEffect(() => {
//     getAllEmployees();
//   }, []);

//   // Fetch all employees
//   const getAllEmployees = async () => {
//     try {
//       const { data } = await axios.get(`${apiUrl}/employee`);
//       setEmployee(data.employees);
//       calculateWeeklySalaries(data.employees); // Calculate weekly salaries once data is fetched
//     } catch (error) {
//       console.error("Error fetching employees:", error);
//     }
//   };

//   const formatAmount = (amount) => {
//     const parsedAmount = parseFloat(amount);
//     return isNaN(parsedAmount)
//       ? "0.00"
//       : parsedAmount.toLocaleString("en-US", {
//           minimumFractionDigits: 3,
//           maximumFractionDigits: 3,
//         });
//   };

//   // Function to group and sum salaries by week
//   const calculateWeeklySalaries = (employees) => {
//     const weeklySalaries = {};

//     employees.forEach((emp) => {
//       const employeeDate = dayjs(emp.date); // Assuming employee has a 'date' field
//       const week = employeeDate.isoWeek(); // Get the ISO week number
//       const year = employeeDate.year(); // Get the year for the week

//       const key = `${year}-W${week}`; // Use year and week as the key to group

//       if (!weeklySalaries[key]) {
//         weeklySalaries[key] = {
//           startDate: employeeDate.startOf("isoWeek").format("YYYY-MM-DD"),
//           endDate: employeeDate.endOf("isoWeek").format("YYYY-MM-DD"),
//           sum: 0,
//         };
//       }
//       weeklySalaries[key].sum += parseFloat(emp.salary) || 0;
//     });

//     setWeeklySalaries(Object.values(weeklySalaries)); // Convert the grouped object to an array
//   };

//   return (
//     <Box>
//       <Typography variant="h6">Weekly Salary Summary</Typography>
//       <Table>
//         <TableHead>
//           <TableRow>
//             <TableCell>Week (From - To)</TableCell>
//             <TableCell align="right">Sum of Salaries</TableCell>
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           {weeklySalaries.map((week, index) => (
//             <TableRow key={index}>
//               <TableCell>
//                 {week.startDate} - {week.endDate}
//               </TableCell>
//               <TableCell align="right">{formatAmount(week.sum)}</TableCell>
//             </TableRow>
//           ))}
//         </TableBody>
//       </Table>
//     </Box>
//   );
// };

// export default SumSalary;

// this code show the start and end of the week and the sum of salary of all the employees

// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import {
//   Box,
//   Typography,
//   Table,
//   TableBody,
//   TableCell,
//   TableHead,
//   TableRow,
//   TableContainer,
//   Paper,
// } from "@mui/material";
// import dayjs from "dayjs";
// import isoWeek from "dayjs/plugin/isoWeek";
// import { useTheme } from "@mui/material/styles";

// dayjs.extend(isoWeek); // Enable week-based functionality

// const apiUrl = process.env.REACT_APP_SERVER_URL;

// const SumSalary = () => {
//   const [employee, setEmployee] = useState([]);
//   const [weeklyDates, setWeeklyDates] = useState([]);
//   const [totalSalary, setTotalSalary] = useState(0);

//   const theme = useTheme();

//   useEffect(() => {
//     getAllEmployees();
//   }, []);

//   // Fetch all employees and calculate total salary
//   const getAllEmployees = async () => {
//     try {
//       const { data } = await axios.get(`${apiUrl}/employee`);
//       setEmployee(data.employees);

//       // Calculate the total salary of all employees
//       const total = data.employees.reduce(
//         (sum, emp) => sum + (parseFloat(emp.salary) || 0),
//         0
//       );
//       setTotalSalary(total);

//       // Generate weekly dates (e.g., from today to 4 weeks back)
//       generateWeeklyDates();
//     } catch (error) {
//       console.error("Error fetching employees:", error);
//     }
//   };

//   // Function to generate weekly date ranges
//   const generateWeeklyDates = () => {
//     const weeks = [];
//     const currentWeekStart = dayjs().startOf("isoWeek");

//     for (let i = 0; i < 4; i++) {
//       // Let's say you want 4 weeks
//       const startDate = currentWeekStart
//         .subtract(i, "week")
//         .startOf("isoWeek")
//         .format("MMM D");
//       const endDate = currentWeekStart
//         .subtract(i, "week")
//         .endOf("isoWeek")
//         .format("MMM D");
//       weeks.push({ startDate, endDate });
//     }

//     setWeeklyDates(weeks);
//   };

//   const formatAmount = (amount) => {
//     const parsedAmount = parseFloat(amount);
//     return isNaN(parsedAmount)
//       ? "0.00"
//       : parsedAmount.toLocaleString("en-US", {
//           minimumFractionDigits: 3,
//           maximumFractionDigits: 3,
//         });
//   };

//   return (
//     <TableContainer
//       component={Paper}
//       sx={{ width: "100%", maxWidth: "550px", margin: "10px" }}
//     >
//       <Table>
//         <TableHead>
//           <TableRow>
//             <TableCell
//               sx={{
//                 padding: "5px",
//                 backgroundColor:
//                   theme.palette.mode === "dark" ? "#44484e" : "#fff",
//                 textAlign: "center",
//                 fontSize: "30px",
//                 fontWeight: "bold",
//               }}
//             >
//               الاسبوع
//             </TableCell>
//             <TableCell
//               //   align="right"
//               sx={{
//                 // padding: "5px",
//                 backgroundColor:
//                   theme.palette.mode === "dark" ? "#44484e" : "#fff",
//                 textAlign: "center",
//                 fontSize: "25px",
//                 fontWeight: "bold",
//               }}
//             >
//               مجموع الرواتب
//             </TableCell>
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           {weeklyDates.map((week, index) => (
//             <TableRow key={index}>
//               <TableCell
//                 sx={{
//                   // padding: "4px",
//                   padding: "15px 10px ",
//                   textAlign: "center",
//                   fontSize: "18px",
//                 }}
//               >
//                 {week.startDate} - {week.endDate}
//               </TableCell>
//               <TableCell
//                 align="right"
//                 sx={{
//                   // padding: "4px",
//                   padding: "15px 30px ",
//                   textAlign: "center",
//                   fontSize: "18px",
//                 }}
//               >
//                 {formatAmount(totalSalary)}
//               </TableCell>{" "}
//               {/* Constant total salary */}
//             </TableRow>
//           ))}
//         </TableBody>
//       </Table>
//     </TableContainer>
//   );
// };

// export default SumSalary;

/////////////////////////////////

// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import {
//   Box,
//   Typography,
//   Table,
//   TableBody,
//   TableCell,
//   TableHead,
//   TableRow,
// } from "@mui/material";
// import dayjs from "dayjs";
// import isoWeek from "dayjs/plugin/isoWeek";

// dayjs.extend(isoWeek); // Enable week-based functionality

// const apiUrl = process.env.REACT_APP_SERVER_URL;

// const SumSalary = () => {
//   const [employee, setEmployee] = useState([]);
//   const [weeklySalaries, setWeeklySalaries] = useState([]);

//   useEffect(() => {
//     getAllEmployees();
//   }, []);

//   // Fetch all employees
//   const getAllEmployees = async () => {
//     try {
//       const { data } = await axios.get(`${apiUrl}/employee`);
//       setEmployee(data.employees);
//       calculateWeeklySalaries(data.employees); // Calculate weekly salaries once data is fetched
//     } catch (error) {
//       console.error("Error fetching employees:", error);
//     }
//   };

//   const formatAmount = (amount) => {
//     const parsedAmount = parseFloat(amount);
//     return isNaN(parsedAmount)
//       ? "0.00"
//       : parsedAmount.toLocaleString("en-US", {
//           minimumFractionDigits: 3,
//           maximumFractionDigits: 3,
//         });
//   };

//   // Function to group and sum salaries by week
//   const calculateWeeklySalaries = (employees) => {
//     const weeklySalaries = {};

//     employees.forEach((emp) => {
//       const employeeDate = dayjs(emp.date); // Assuming employee has a 'date' field
//       const weekStart = employeeDate.day(6); // Start the week on Saturday
//       const weekEnd = weekStart.add(6, "day"); // End the week on Friday (7-day range)

//       const key = `${weekStart.year()}-W${weekStart.week()}`; // Use year and week number as the key

//       if (!weeklySalaries[key]) {
//         weeklySalaries[key] = {
//           startDate: weekStart.format("YYYY-MM-DD"),
//           endDate: weekEnd.format("YYYY-MM-DD"),
//           sum: 0,
//         };
//       }
//       weeklySalaries[key].sum += parseFloat(emp.salary) || 0;
//     });

//     // Sort the weekly salaries by start date in descending order (newest first)
//     const sortedWeeklySalaries = Object.values(weeklySalaries).sort((a, b) =>
//       dayjs(a.startDate).isAfter(dayjs(b.startDate)) ? -1 : 1
//     );

//     setWeeklySalaries(sortedWeeklySalaries); // Set sorted weekly salaries
//   };

//   return (
//     <Box>
//       <Typography variant="h6">Weekly Salary Summary</Typography>
//       <Table>
//         <TableHead>
//           <TableRow>
//             <TableCell>Week (From - To)</TableCell>
//             <TableCell align="right">Sum of Salaries</TableCell>
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           {weeklySalaries.map((week, index) => (
//             <TableRow key={index}>
//               <TableCell>
//                 {week.startDate} - {week.endDate}
//               </TableCell>
//               <TableCell align="right">{formatAmount(week.sum)}</TableCell>
//             </TableRow>
//           ))}
//         </TableBody>
//       </Table>
//     </Box>
//   );
// };

// export default SumSalary;

import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
} from "@mui/material";
import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import { useTheme } from "@mui/material/styles";

dayjs.extend(isoWeek); // Enable week-based functionality

const apiUrl = process.env.REACT_APP_SERVER_URL;

const SumSalary = () => {
  const [employee, setEmployee] = useState([]);
  const [weeklySalaries, setWeeklySalaries] = useState([]);
  const currentDate = dayjs(); // Get the current date

  const theme = useTheme();

  useEffect(() => {
    getAllEmployees();
  }, []);

  // Fetch all employees
  const getAllEmployees = async () => {
    try {
      const { data } = await axios.get(`${apiUrl}/employee`);
      setEmployee(data.employees);
      calculateWeeklySalaries(data.employees); // Calculate weekly salaries once data is fetched
    } catch (error) {
      console.error("Error fetching employees:", error);
    }
  };

  const formatAmount = (amount) => {
    const parsedAmount = parseFloat(amount);
    return isNaN(parsedAmount)
      ? "0.00"
      : parsedAmount.toLocaleString("en-US", {
          minimumFractionDigits: 3,
          maximumFractionDigits: 3,
        });
  };

  // Function to group and sum salaries by week
  //   const calculateWeeklySalaries = (employees) => {
  //     const weeklySalaries = {};

  //     employees.forEach((emp) => {
  //       const employeeDate = dayjs(emp.date); // Using the 'date' field from the employee schema
  //       const weekStart = employeeDate.day(6); // Set the week to start on Saturday
  //       const weekEnd = weekStart.add(6, "day"); // Set the week to end on Friday

  //       const key = `${weekStart.year()}-W${weekStart.week()}`; // Use year and week number as the key

  //       if (!weeklySalaries[key]) {
  //         weeklySalaries[key] = {
  //           startDate: weekStart.format("YYYY-MM-DD"),
  //           endDate: weekEnd.format("YYYY-MM-DD"),
  //           sum: 0,
  //         };
  //       }
  //       weeklySalaries[key].sum += parseFloat(emp.salary) || 0;
  //     });

  //     // Exclude current ongoing week (if today's date is before the end of the week)
  //     const filteredWeeklySalaries = Object.values(weeklySalaries).filter(
  //       (week) => dayjs(week.endDate).isBefore(currentDate.day(6)) // Only include weeks that have fully ended (before current week Saturday)
  //     );

  //     // Sort the weekly salaries by start date in descending order (newest first)
  //     const sortedWeeklySalaries = filteredWeeklySalaries.sort((a, b) =>
  //       dayjs(a.startDate).isAfter(dayjs(b.startDate)) ? -1 : 1
  //     );

  //     setWeeklySalaries(sortedWeeklySalaries); // Set sorted and filtered weekly salaries
  //   };

  // const calculateWeeklySalaries = (employees) => {
  //   const weeklySalaries = {};

  //   employees.forEach((emp) => {
  //     const employeeDate = dayjs(emp.date); // Using the 'date' field from the employee schema
  //     const weekStart = employeeDate.isoWeekday(6).startOf("isoWeek"); // Start the week on Saturday
  //     const weekEnd = weekStart.add(6, "day"); // Set the week to end on Friday

  //     const key = `${weekStart.year()}-W${weekStart.isoWeek()}`; // Use year and week number as the key

  //     if (!weeklySalaries[key]) {
  //       weeklySalaries[key] = {
  //         startDate: weekStart.format("YYYY-MM-DD"),
  //         endDate: weekEnd.format("YYYY-MM-DD"),
  //         sum: 0,
  //       };
  //     }
  //     weeklySalaries[key].sum += parseFloat(emp.salary) || 0;
  //   });

  //   const filteredWeeklySalaries = Object.values(weeklySalaries).filter(
  //     (week) => dayjs(week.endDate).isBefore(currentDate) // Exclude ongoing week
  //   );

  //   const sortedWeeklySalaries = filteredWeeklySalaries.sort((a, b) =>
  //     dayjs(a.startDate).isAfter(dayjs(b.startDate)) ? -1 : 1
  //   );

  //   setWeeklySalaries(sortedWeeklySalaries); // Set sorted and filtered weekly salaries
  // };

  //   const calculateWeeklySalaries = (employees) => {
  //     const weeklySalaries = {};

  //     employees.forEach((emp) => {
  //       const employeeDate = dayjs(emp.date); // Using the 'date' field from the employee schema
  //       const weekStart = employeeDate.day(6).startOf("day"); // Set the week to start on Saturday
  //       const weekEnd = weekStart.add(6, "day"); // Set the week to end on Friday

  //       const key = `${weekStart.year()}-W${weekStart.isoWeek()}`; // Use year and week number as the key

  //       if (!weeklySalaries[key]) {
  //         weeklySalaries[key] = {
  //           startDate: weekStart.format("YYYY-MM-DD"),
  //           endDate: weekEnd.format("YYYY-MM-DD"),
  //           sum: 0,
  //         };
  //       }
  //       weeklySalaries[key].sum += parseFloat(emp.salary) || 0;
  //     });

  //     // Get the current date to filter the ongoing week
  //     const currentDate = dayjs(); // Today's date

  //     // Exclude current ongoing week (if today's date is before the end of the week)
  //     const filteredWeeklySalaries = Object.values(weeklySalaries).filter(
  //       (week) => dayjs(week.endDate).isBefore(currentDate.startOf("day")) // Only include weeks that have fully ended
  //     );

  //     // Sort the weekly salaries by start date in descending order (newest first)
  //     const sortedWeeklySalaries = filteredWeeklySalaries.sort(
  //       (a, b) => dayjs(b.startDate).valueOf() - dayjs(a.startDate).valueOf() // Sort by timestamp
  //     );

  //     setWeeklySalaries(sortedWeeklySalaries); // Set sorted and filtered weekly salaries
  //   };

  const calculateWeeklySalaries = (employees) => {
    const weeklySalaries = {};

    employees.forEach((emp) => {
      const employeeDate = dayjs(emp.date); // Using the 'date' field from the employee schema

      // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
      const dayOfWeek = employeeDate.day();

      // Calculate how many days to subtract to get to the previous Saturday
      const daysToSubtract = (dayOfWeek + 1) % 7; // This gives us how many days to go back to Saturday

      // Calculate the start of the week (Saturday)
      const weekStart = employeeDate
        .subtract(daysToSubtract, "day")
        .startOf("day"); // Set to Saturday
      const weekEnd = weekStart.add(6, "day"); // Set to the following Friday

      const key = `${weekStart.year()}-W${weekStart.isoWeek()}`; // Use year and week number as the key

      if (!weeklySalaries[key]) {
        weeklySalaries[key] = {
          startDate: weekStart.format("YYYY-MM-DD"),
          endDate: weekEnd.format("YYYY-MM-DD"),
          sum: 0,
        };
      }
      weeklySalaries[key].sum += parseFloat(emp.salary) || 0; // Accumulate the salary
    });

    // Exclude current ongoing week (if today's date is before the end of the week)
    const filteredWeeklySalaries = Object.values(weeklySalaries).filter(
      (week) => dayjs(week.endDate).isBefore(dayjs()) // Only include weeks that have fully ended
    );

    // Sort the weekly salaries by start date in descending order (newest first)
    const sortedWeeklySalaries = filteredWeeklySalaries.sort((a, b) =>
      dayjs(a.startDate).isAfter(dayjs(b.startDate)) ? -1 : 1
    );

    setWeeklySalaries(sortedWeeklySalaries); // Set sorted and filtered weekly salaries
  };

    // Calculate the sum of all salaries
    const sumOfSalaries = employee.reduce(
      (total, emp) => total + (parseFloat(emp.salary) || 0),
      0
    );

  return (
    <TableContainer
      component={Paper}
      sx={{ width: "100%", maxWidth: "550px", margin: "10px" }}
    >
      <Table>
        <TableHead
          sx={{
            padding: "5px",
            backgroundColor: theme.palette.mode === "dark" ? "#44484e" : "#fff",
            textAlign: "center",
            fontSize: "30px",
            fontWeight: "bold",
          }}
        >
          <TableRow>
            <TableCell
              sx={{
                // padding: "4px",
                padding: "20px 20px",
                textAlign: "center",
                fontSize: "25px",
                fontWeight: "bold",
              }}
            >
              الاسبوع
            </TableCell>
            <TableCell
              align="right"
              sx={{
                // padding: "4px",
                padding: "20px 20px",
                textAlign: "center",
                fontSize: "20px",
                fontWeight: "bold",
              }}
            >
              مجموع الرواتب
            </TableCell>
            <TableCell
              align="right"
              sx={{
                // padding: "4px",
                padding: "20px 20px",
                textAlign: "center",
                fontSize: "20px",
                fontWeight: "bold",
              }}
            >
              المجموع الكلي
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {weeklySalaries.map((week, index) => (
            <TableRow key={index}>
              <TableCell
                sx={{
                  // padding: "4px",
                  padding: "15px 10px ",
                  textAlign: "center",
                  fontSize: "18px",
                }}
              >
                {week.startDate} - {week.endDate}
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  // padding: "4px",
                  padding: "15px 10px ",
                  textAlign: "center",
                  fontSize: "18px",
                }}
              >
                {formatAmount(week.sum)}
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  // padding: "4px",
                  padding: "15px 10px ",
                  textAlign: "center",
                  fontSize: "18px",
                }}
              >
                {formatAmount(sumOfSalaries)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SumSalary;
