/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
// import React, { useEffect, useState } from 'react'
// import { useTheme } from "@mui/material/styles";
// import { Card, CardContent, Grid, Typography } from '@mui/material';
// import  dayjs  from 'dayjs';
// import  axios  from 'axios';

// const apiUrl = process.env.REACT_APP_SERVER_URL;


// const IncomeInventory = () => {
//     const [incomes, setIncomes] = useState([]);
//     const [weeklyIncome, setWeeklyIncome] = useState(0);
//     const [monthlyIncome, setMonthlyIncome] = useState(0);
//     const [yearlyIncome, setYearlyIncome] = useState(0);


//     const theme = useTheme();

//     const sumIncomesByPeriod = (incomes, period) => {
//       const now = dayjs();
//       let filteredIncomes = incomes;

//       if (period === "weekly") {
//         filteredIncomes = incomes.filter((income) =>
//           dayjs(income.date).isAfter(now.subtract(1, "week"))
//         );
//       } else if (period === "monthly") {
//         filteredIncomes = incomes.filter((income) =>
//           dayjs(income.date).isAfter(now.subtract(1, "month"))
//         );
//       } else if (period === "yearly") {
//         filteredIncomes = incomes.filter((income) =>
//           dayjs(income.date).isAfter(now.subtract(1, "year"))
//         );
//       }

//       return filteredIncomes.reduce(
//         (total, income) => total + parseFloat(income.amount),
//         0
//       );
//     };

//     useEffect(() => {
//       axios
//         .get(`${apiUrl}/income`)
//         .then((response) => {
//             // console.log(response.data); 
//           setIncomes(response.data);

//           // Calculate weekly, monthly, yearly incomes
//           setWeeklyIncome(sumIncomesByPeriod(response.data, "weekly"));
//           setMonthlyIncome(sumIncomesByPeriod(response.data, "monthly"));
//           setYearlyIncome(sumIncomesByPeriod(response.data, "yearly"));
//         })
//         .catch((error) => {
//           console.error("There was an error fetching the incomes!", error);
//         });
//     }, []);

    


//   return (
//     <Grid container spacing={2}>
//       <Grid item xs={12} sm={6} md={4}>
//         <Card
//           sx={{
//             display: "flex",
//             flexDirection: "column",
//             alignItems: "center",
//             padding: "20px",
//             color: theme.palette.mode === "dark" ? "#44484e" : "#000",
//             backgroundColor: theme.palette.mode === "dark" ? "#fff" : "#44484e",
//           }}
//         >
//           <CardContent>
//             <Typography
//               variant="h5"
//               component="div"
//               gutterBottom
//               fontWeight={"bold"}
//               sx={{
//                 color: theme.palette.mode === "dark" ? "#44484e" : "#fff",
//               }}
//             >
//               الدخل الأسبوعي:
//             </Typography>
//             <Typography
//               variant="h5"
//               component="div"
//               gutterBottom
//               fontWeight={"bold"}
//               sx={{
//                 color: theme.palette.mode === "dark" ? "#44484e" : "#fff",
//               }}
//             >
//               {weeklyIncome.toLocaleString()}
//             </Typography>
//           </CardContent>
//         </Card>
//       </Grid>
//       <Grid item xs={12} sm={6} md={4}>
//         <Card
//           sx={{
//             display: "flex",
//             flexDirection: "column",
//             alignItems: "center",
//             padding: "20px",
//             color: theme.palette.mode === "dark" ? "#44484e" : "#000",
//             backgroundColor: theme.palette.mode === "dark" ? "#fff" : "#44484e",
//           }}
//         >
//           <CardContent>
//             <Typography
//               variant="h5"
//               component="div"
//               gutterBottom
//               fontWeight={"bold"}
//               sx={{
//                 color: theme.palette.mode === "dark" ? "#44484e" : "#fff",
//               }}
//             >
//               الدخل الشهري:
//             </Typography>
//             <Typography
//               variant="h5"
//               component="div"
//               gutterBottom
//               fontWeight={"bold"}
//               sx={{
//                 color: theme.palette.mode === "dark" ? "#44484e" : "#fff",
//               }}
//             >
//               {monthlyIncome.toLocaleString()}
//             </Typography>
//           </CardContent>
//         </Card>
//       </Grid>
//       <Grid item xs={12} sm={6} md={4}>
//         <Card
//           sx={{
//             display: "flex",
//             flexDirection: "column",
//             alignItems: "center",
//             padding: "20px",
//             color: theme.palette.mode === "dark" ? "#44484e" : "#000",
//             backgroundColor: theme.palette.mode === "dark" ? "#fff" : "#44484e",
//           }}
//         >
//           <CardContent>
//             <Typography
//               variant="h5"
//               component="div"
//               gutterBottom
//               fontWeight={"bold"}
//               sx={{
//                 color: theme.palette.mode === "dark" ? "#44484e" : "#fff",
//               }}
//             >
//               الدخل السنوي:
//             </Typography>
//             <Typography
//               variant="h5"
//               component="div"
//               gutterBottom
//               fontWeight={"bold"}
//               sx={{
//                 color: theme.palette.mode === "dark" ? "#44484e" : "#fff",
//               }}
//             >
//               {yearlyIncome.toLocaleString()}
//             </Typography>
//           </CardContent>
//         </Card>
//       </Grid>
//     </Grid>
//   );
// }

// export default IncomeInventory


// import React, { useEffect, useState } from "react";
// import { useTheme } from "@mui/material/styles";
// import {
//   Grid,
//   Typography,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
// } from "@mui/material";
// import dayjs from "dayjs";
// import isoWeek from "dayjs/plugin/isoWeek"; // For getting ISO week number
// import localizedFormat from "dayjs/plugin/localizedFormat"; // For better date formatting
// import  axios  from 'axios';

// dayjs.extend(isoWeek);
// dayjs.extend(localizedFormat);

// const apiUrl = process.env.REACT_APP_SERVER_URL;

// const IncomeInventory = () => {
//   const [incomes, setIncomes] = useState([]);
//   const [weeklyIncomes, setWeeklyIncomes] = useState([]);
//   const [monthlyIncomes, setMonthlyIncomes] = useState([]);
//   const [yearlyIncomes, setYearlyIncomes] = useState([]);

//   const theme = useTheme();

//   // Function to format week as "from date to date"
//   const getWeekRange = (weekNumber, year) => {
//     const startOfWeek = dayjs().year(year).isoWeek(weekNumber).startOf("week");
//     const endOfWeek = dayjs().year(year).isoWeek(weekNumber).endOf("week");
//     return `${startOfWeek.format("MMM D")} - ${endOfWeek.format("MMM D")}`;
//   };

//   // Function to group incomes by weekly, monthly, or yearly
//   const groupIncomesByPeriod = (incomes, period) => {
//     let groupedIncomes = {};

//     if (period === "weekly") {
//       incomes.forEach((income) => {
//         const weekNumber = dayjs(income.date).isoWeek();
//         const year = dayjs(income.date).year();
//         const weekRange = `${getWeekRange(weekNumber, year)}`;

//         if (!groupedIncomes[weekRange]) {
//           groupedIncomes[weekRange] = 0;
//         }
//         groupedIncomes[weekRange] += parseFloat(income.amount);
//       });
//     } else if (period === "monthly") {
//       incomes.forEach((income) => {
//         const monthName = dayjs(income.date).format("MMMM"); // Get month name
//         if (!groupedIncomes[monthName]) {
//           groupedIncomes[monthName] = 0;
//         }
//         groupedIncomes[monthName] += parseFloat(income.amount);
//       });
//     } else if (period === "yearly") {
//       incomes.forEach((income) => {
//         const year = dayjs(income.date).year();
//         if (!groupedIncomes[year]) {
//           groupedIncomes[year] = 0;
//         }
//         groupedIncomes[year] += parseFloat(income.amount);
//       });
//     }

//     return Object.entries(groupedIncomes).map(([period, sum]) => ({
//       period,
//       sum,
//     }));
//   };

//   useEffect(() => {
//     axios
//       .get(`${apiUrl}/income`)
//       .then((response) => {
//         setIncomes(response.data);

//         // Group incomes by weekly, monthly, yearly
//         setWeeklyIncomes(groupIncomesByPeriod(response.data, "weekly"));
//         setMonthlyIncomes(groupIncomesByPeriod(response.data, "monthly"));
//         setYearlyIncomes(groupIncomesByPeriod(response.data, "yearly"));
//       })
//       .catch((error) => {
//         console.error("There was an error fetching the incomes!", error);
//       });
//   }, []);

//   // Helper function to render the tables
//   const renderIncomeTable = (title, data, periodLabel) => (
//     <TableContainer component={Paper} sx={{ marginTop: "20px" }}>
//       <Typography
//         variant="h6"
//         component="div"
//         sx={{
//           padding: "10px",
//           backgroundColor: theme.palette.mode === "dark" ? "#44484e" : "#fff",
//         }}
//       >
//         {title} Income
//       </Typography>
//       <Table>
//         <TableHead>
//           <TableRow>
//             <TableCell>{periodLabel}</TableCell>
//             <TableCell align="right">Income Sum</TableCell>
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           {data.map(({ period, sum }) => (
//             <TableRow key={period}>
//               <TableCell>{period}</TableCell>
//               <TableCell align="right">{sum.toLocaleString()}</TableCell>
//             </TableRow>
//           ))}
//         </TableBody>
//       </Table>
//     </TableContainer>
//   );

//   return (
//     <Grid container spacing={2}>
//       <Grid item xs={12}>
//         {renderIncomeTable("Weekly", weeklyIncomes, "Week Range")}
//       </Grid>
//       <Grid item xs={12}>
//         {renderIncomeTable("Monthly", monthlyIncomes, "Month")}
//       </Grid>
//       <Grid item xs={12}>
//         {renderIncomeTable("Yearly", yearlyIncomes, "Year")}
//       </Grid>
//     </Grid>
//   );
// };

// export default IncomeInventory;


import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek"; // For getting ISO week number
import localizedFormat from "dayjs/plugin/localizedFormat"; // For better date formatting
import  axios  from 'axios';

dayjs.extend(isoWeek);
dayjs.extend(localizedFormat);

const apiUrl = process.env.REACT_APP_SERVER_URL;

const IncomeInventory = () => {
  const [incomes, setIncomes] = useState([]);
  const [weeklyIncomes, setWeeklyIncomes] = useState([]);
  const [monthlyIncomes, setMonthlyIncomes] = useState([]);
  const [yearlyIncomes, setYearlyIncomes] = useState([]);

  const theme = useTheme();

  const getWeekRange = (weekNumber, year) => {
    const startOfWeek = dayjs().year(year).isoWeek(weekNumber).startOf("week");
    const endOfWeek = dayjs().year(year).isoWeek(weekNumber).endOf("week");
    return `${startOfWeek.format("MMM D")} - ${endOfWeek.format("MMM D")}`;
  };

  const groupIncomesByPeriod = (incomes, period) => {
    let groupedIncomes = {};

    if (period === "weekly") {
      incomes.forEach((income) => {
        const weekNumber = dayjs(income.date).isoWeek();
        const year = dayjs(income.date).year();
        const weekRange = `${getWeekRange(weekNumber, year)}`;

        if (!groupedIncomes[weekRange]) {
          groupedIncomes[weekRange] = 0;
        }
        groupedIncomes[weekRange] += parseFloat(income.amount);
      });
    } else if (period === "monthly") {
      incomes.forEach((income) => {
        const monthName = dayjs(income.date).format("MMMM"); // Get month name
        if (!groupedIncomes[monthName]) {
          groupedIncomes[monthName] = 0;
        }
        groupedIncomes[monthName] += parseFloat(income.amount);
      });
    } else if (period === "yearly") {
      incomes.forEach((income) => {
        const year = dayjs(income.date).year();
        if (!groupedIncomes[year]) {
          groupedIncomes[year] = 0;
        }
        groupedIncomes[year] += parseFloat(income.amount);
      });
    }

    return Object.entries(groupedIncomes).map(([period, sum]) => ({
      period,
      sum,
    }));
  };

  useEffect(() => {
    axios
      .get(`${apiUrl}/income`)
      .then((response) => {
        setIncomes(response.data);

        // Group incomes by weekly, monthly, yearly
        setWeeklyIncomes(groupIncomesByPeriod(response.data, "weekly"));
        setMonthlyIncomes(groupIncomesByPeriod(response.data, "monthly"));
        setYearlyIncomes(groupIncomesByPeriod(response.data, "yearly"));
      })
      .catch((error) => {
        console.error("There was an error fetching the incomes!", error);
      });
  }, []);

  const formatAmount = (amount) => {
    const parsedAmount = parseFloat(amount);
    return isNaN(parsedAmount)
      ? "0.00"
      : parsedAmount.toLocaleString("en-US", {
          minimumFractionDigits: 3,
          maximumFractionDigits: 3,
        });
  };

  // Helper function to render the tables
  const renderIncomeTable = (title, data, periodLabel) => (
    <TableContainer
      component={Paper}
      sx={{ width: "100%", maxWidth: "550px", margin: "10px" }}
    >
      <Typography
        variant="h6"
        component="div"
        sx={{
          padding: "5px",
          backgroundColor: theme.palette.mode === "dark" ? "#44484e" : "#fff",
          textAlign: "center",
          fontSize: "30px",
          fontWeight: "bold",
        }}
      >
        {title}
      </Typography>
      <Table sx={{ minWidth: 380 }}>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                // padding: "4px",
                padding: "15px 20px",
                textAlign: "center",
                fontSize: "25px",
                fontWeight: "bold",
              }}
            >
              {periodLabel}
            </TableCell>
            <TableCell
              align="right"
              sx={{
                // padding: "4px",
                padding: "15px 20px",
                textAlign: "center",
                fontSize: "25px",
                fontWeight: "bold",
              }}
            >
              المجموع
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(({ period, sum }) => (
            <TableRow key={period}>
              <TableCell
                sx={{
                  // padding: "4px",
                  padding: "15px 10px ",
                  textAlign: "center",
                  fontSize: "18px",
                }}
              >
                {period}
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  // padding: "4px",
                  padding: "15px 30px ",
                  textAlign: "center",
                  fontSize: "18px",
                }}
              >
                {formatAmount(sum)}
                {/* .toLocaleString() */}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <Grid
      container
      spacing={2}
      sx={{ justifyContent: "center", flexWrap: "nowrap", overflowX: "auto" }}
    >
      <Grid item>
        {/* {renderIncomeTable("Weekly", weeklyIncomes, "Week Range")} */}
        {renderIncomeTable("الاسبوعي", weeklyIncomes, " الاسبوع")}
      </Grid>
      <Grid item>
        {/* {renderIncomeTable("Monthly", monthlyIncomes, "Month")} */}
        {renderIncomeTable("الشهري", monthlyIncomes, "الشهر")}
      </Grid>
      <Grid item>
        {/* {renderIncomeTable("Yearly", yearlyIncomes, "Year")} */}
        {renderIncomeTable("السنوي", yearlyIncomes, "السنة")}
      </Grid>
    </Grid>
  );
};

export default IncomeInventory;
