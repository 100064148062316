/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek"; // For getting ISO week number
import localizedFormat from "dayjs/plugin/localizedFormat"; // For better date formatting
import axios from "axios";

dayjs.extend(isoWeek);
dayjs.extend(localizedFormat);

const apiUrl = process.env.REACT_APP_SERVER_URL;

const ExpensesInventory = () => {
    const [expenses, setExpenses] = useState([]);
    const [weeklyExpenses, setWeeklyExpenses] = useState([]);
    const [monthlyExpenses, setMonthlyExpenses] = useState([]);
    const [yearlyExpenses, setYearlyExpenses] = useState([]);

  const theme = useTheme();

  const getWeekRange = (weekNumber, year) => {
    const startOfWeek = dayjs().year(year).isoWeek(weekNumber).startOf("week");
    const endOfWeek = dayjs().year(year).isoWeek(weekNumber).endOf("week");
    return `${startOfWeek.format("MMM D")} - ${endOfWeek.format("MMM D")}`;
  };

  const groupExpensesByPeriod = (expenses, period) => {
    let groupedExpenses = {};

    if (period === "weekly") {
      expenses.forEach((expense) => {
        const weekNumber = dayjs(expense.date).isoWeek();
        const year = dayjs(expense.date).year();
        const weekRange = `${getWeekRange(weekNumber, year)}`;

        if (!groupedExpenses[weekRange]) {
          groupedExpenses[weekRange] = 0;
        }
        groupedExpenses[weekRange] += parseFloat(expense.amount);
      });
    } else if (period === "monthly") {
      expenses.forEach((expense) => {
        const monthName = dayjs(expense.date).format("MMMM"); // Get month name
        if (!groupedExpenses[monthName]) {
          groupedExpenses[monthName] = 0;
        }
        groupedExpenses[monthName] += parseFloat(expense.amount);
      });
    } else if (period === "yearly") {
      expenses.forEach((expense) => {
        const year = dayjs(expense.date).year();
        if (!groupedExpenses[year]) {
          groupedExpenses[year] = 0;
        }
        groupedExpenses[year] += parseFloat(expense.amount);
      });
    }

    return Object.entries(groupedExpenses).map(([period, sum]) => ({
      period,
      sum,
    }));
  };

  useEffect(() => {
    axios
      .get(`${apiUrl}/expense`)
      .then((response) => {
        setExpenses(response.data);

        // Group incomes by weekly, monthly, yearly
        setWeeklyExpenses(groupExpensesByPeriod(response.data, "weekly"));
        setMonthlyExpenses(groupExpensesByPeriod(response.data, "monthly"));
        setYearlyExpenses(groupExpensesByPeriod(response.data, "yearly"));
      })
      .catch((error) => {
        console.error("There was an error fetching the Expenses!", error);
      });
  }, []);

  const formatAmount = (amount) => {
    const parsedAmount = parseFloat(amount);
    return isNaN(parsedAmount)
      ? "0.00"
      : parsedAmount.toLocaleString("en-US", {
          minimumFractionDigits: 3,
          maximumFractionDigits: 3,
        });
  };

  // Helper function to render the tables
  const renderExpenseTable = (title, data, periodLabel) => (
    <TableContainer
      component={Paper}
      sx={{ width: "100%", maxWidth: "550px", margin: "10px" }}
    >
      <Typography
        variant="h6"
        component="div"
        sx={{
          padding: "5px",
          backgroundColor: theme.palette.mode === "dark" ? "#44484e" : "#fff",
          textAlign: "center",
          fontSize: "30px",
          fontWeight: "bold",
        }}
      >
        {title}
      </Typography>
      <Table sx={{ minWidth: 380 }}>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                // padding: "4px",
                padding: "15px 20px",
                textAlign: "center",
                fontSize: "25px",
                fontWeight: "bold",
              }}
            >
              {periodLabel}
            </TableCell>
            <TableCell
              align="right"
              sx={{
                // padding: "4px",
                padding: "15px 20px",
                textAlign: "center",
                fontSize: "25px",
                fontWeight: "bold",
              }}
            >
              المجموع
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(({ period, sum }) => (
            <TableRow key={period}>
              <TableCell
                sx={{
                  // padding: "4px",
                  padding: "15px 10px ",
                  textAlign: "center",
                  fontSize: "18px",
                }}
              >
                {period}
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  // padding: "4px",
                  padding: "15px 30px ",
                  textAlign: "center",
                  fontSize: "18px",
                }}
              >
                {formatAmount(sum)}
                {/* .toLocaleString() */}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <Grid
      container
      spacing={2}
      sx={{ justifyContent: "center", flexWrap: "nowrap", overflowX: "auto" }}
    >
      <Grid item>
        {/* {renderIncomeTable("Weekly", weeklyIncomes, "Week Range")} */}
        {renderExpenseTable("الاسبوعي", weeklyExpenses, " الاسبوع")}
      </Grid>
      <Grid item>
        {/* {renderIncomeTable("Monthly", monthlyIncomes, "Month")} */}
        {renderExpenseTable("الشهري", monthlyExpenses, "الشهر")}
      </Grid>
      <Grid item>
        {/* {renderIncomeTable("Yearly", yearlyIncomes, "Year")} */}
        {renderExpenseTable("السنوي", yearlyExpenses, "السنة")}
      </Grid>
    </Grid>
  );
};

export default ExpensesInventory;
