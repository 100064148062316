import React, { useState } from "react";
import {
  Button,
  FormControl,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import dayjs from "dayjs";
import { useTheme } from "@mui/material/styles"; 

const apiUrl = process.env.REACT_APP_SERVER_URL;

const CreateExpenses = ({ onClose }) => {
  const [expense, setExpense] = useState({
    amount: "0",
    date: dayjs().format("YYYY-MM-DD"),
    description: "",
  });

  
  const theme = useTheme();

  const handleAddExpenses = async() => {
    axios
      .post(`${apiUrl}/expense/createExpenses`, expense)
      .then(() => {
        onClose();
      })
      .catch((error) => {
        console.error("Error adding debt:", error);
      });

  };

  
  

  return (
    <Grid item xs={12} md={4}>
      <Paper sx={{ padding: "16px", textAlign: "center" }}>
        <Typography variant="h5" gutterBottom fontWeight={"bold"}>
          اضافة مصرف
        </Typography>
        <FormControl fullWidth margin="normal">
          <TextField
            label="المبلغ"
            value={expense.amount}
            onChange={(e) =>
              setExpense({
                ...expense,
                amount: e.target.value,
              })
            }
            variant="standard"
            InputProps={{
              style: { textAlign: "right" },
              inputMode: "numeric",
              pattern: "[0-9]*",
            }}
            InputLabelProps={{ style: { right: 30, left: "auto" } }}
            sx={{
              "& .MuiInput-underline": {
                "&:before": {
                  // borderBottomColor: "#44484e",
                  borderBottomColor:
                    theme.palette.mode === "dark" ? "#fff" : "#44484e",
                },
                "&:hover:not(.Mui-disabled):before": {
                  borderBottomColor: "#44484e",
                },
                "&:after": {
                  borderBottomColor: "#44484e",
                },
              },
              "& .MuiInputLabel-root": {
                // color: "#44484e",
                color: theme.palette.mode === "dark" ? "#fff" : "#44484e",
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: "#44484e",
              },
            }}
          />
        </FormControl>
        <FormControl fullWidth margin="normal">
          <TextField
            label="ملاحظات"
            value={expense.description}
            onChange={(e) =>
              setExpense({
                ...expense,
                description: e.target.value,
              })
            }
            variant="standard"
            InputProps={{
              style: { textAlign: "right" },
              inputMode: "numeric",
              pattern: "[0-9]*",
            }}
            InputLabelProps={{ style: { right: 30, left: "auto" } }}
            sx={{
              "& .MuiInput-underline": {
                "&:before": {
                  // borderBottomColor: "#44484e",
                  borderBottomColor:
                    theme.palette.mode === "dark" ? "#fff" : "#44484e",
                },
                "&:hover:not(.Mui-disabled):before": {
                  borderBottomColor: "#44484e",
                },
                "&:after": {
                  borderBottomColor: "#44484e",
                },
              },
              "& .MuiInputLabel-root": {
                // color: "#44484e",
                color: theme.palette.mode === "dark" ? "#fff" : "#44484e",
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: "#44484e",
              },
            }}
          />
        </FormControl>
        <FormControl fullWidth margin="normal">
          <TextField
            type="date"
            value={expense.date}
            onChange={(e) => setExpense({ ...expense, date: e.target.value })}
            variant="standard"
            InputProps={{ style: { textAlign: "right" } }}
            InputLabelProps={{
              style: { right: 30, left: "auto" },
              shrink: true,
            }}
            sx={{
              "& .MuiInput-underline": {
                "&:before": {
                  // borderBottomColor: "#44484e",
                  borderBottomColor:
                    theme.palette.mode === "dark" ? "#fff" : "#44484e",
                },
                "&:hover:not(.Mui-disabled):before": {
                  borderBottomColor: "#44484e",
                },
                "&:after": {
                  borderBottomColor: "#44484e",
                },
              },
              "& input[type='date']::-webkit-calendar-picker-indicator": {
                filter:
                  theme.palette.mode === "dark" ? "invert(1)" : "invert(0)",
              },
              "& .MuiInputLabel-root": {
                // color: "#44484e",
                color: theme.palette.mode === "dark" ? "#fff" : "#44484e",
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: "#44484e",
              },
            }}
          />
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddExpenses}
          sx={{
            // background: "#44484e",
            fontWeight: "bold",
            fontSize: "20px",
            margin: "20px 0 10px 0",
            background: theme.palette.mode === "dark" ? "#fff" : "#44484e",

            "&:hover": {
              backgroundColor: "#5c6169",
            },
          }}
        >
          اضافة
        </Button>
      </Paper>
    </Grid>
  );
};

export default CreateExpenses;
