import React, { useMemo, useState } from "react";
import {
  Box,
  createTheme,
  CssBaseline,
  IconButton,
  styled,
  ThemeProvider,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";

import MuiAppBar from "@mui/material/AppBar";
import { useNavigate } from "react-router-dom";
import { Brightness4, Brightness7, Home, Menu } from "@mui/icons-material";
import SideListPages from "../../components/SideListPages";
import MainContent from "./MainContent";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const MainPage = () => {
   const [open, setOpen] = useState(false);
   const [dark, setDark] = useState(true);

   const darkTheme = useMemo(
     () =>
       createTheme({
         palette: {
           mode: dark ? "dark" : "light",
         },
       }),
     [dark]
   );

   const handleDrawerOpen = () => {
     setOpen(true);
   };

   const navigate = useNavigate();

   return (
     <ThemeProvider theme={darkTheme}>
       <Box sx={{ display: "flex" }}>
         <CssBaseline />
         <AppBar position="fixed" open={open} sx={{ background: "#44484e" }}>
           <Toolbar>
             <IconButton
               color="inherit"
               aria-label="open drawer"
               onClick={handleDrawerOpen}
               edge="start"
               sx={{
                 marginRight: 5,
                 ...(open && { display: "none" }),
               }}
             >
               <Menu />
             </IconButton>
             <Tooltip title="Go back to home page">
               <IconButton
                 sx={{ mr: 1 }}
                 onClick={() => navigate("/")}
                 color="inherit"
               >
                 <Home />
               </IconButton>
             </Tooltip>
             <Typography
               variant="h6"
               noWrap
               component="div"
               sx={{ flexGrow: 1 }}
             >
               Stored
             </Typography>
             <IconButton onClick={() => setDark(!dark)}>
               {dark ? <Brightness7 /> : <Brightness4 />}
             </IconButton>
           </Toolbar>
         </AppBar>
         <SideListPages {...{ open, setOpen }} />;
         <Box component="main" sx={{ flexGrow: 1, p: 2 }}>
           <DrawerHeader />
           <MainContent />
         </Box>
       </Box>
     </ThemeProvider>
   );
};

export default MainPage;
