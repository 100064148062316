import React, { useState} from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
  Button,
} from "@mui/material";
import DialogWrapper from "../../components/DialogWrapper";
import ExpensesTable from "./Expenses/ExpensesTable.js";
import CreateExpenses from './Expenses/CreateExpenses.js';
import CreateIncome from './Income/CreateIncome.js';
import IncomeTable from "./Income/IncomeTable.js";
import { useTheme } from "@mui/material/styles"; 

const AllExpensesIncome = () => {
  const [openDialog, setOpenDialog] = useState(null);

  const theme = useTheme();

  const handleOpenDialog = (dialogType) => {
    setOpenDialog(dialogType);
  };

  const handleCloseDialog = () => {
    setOpenDialog(null);
    window.location.reload();
  };

  return (
    <Container>
      <Typography
        variant="h4"
        component="h1"
        gutterBottom
        style={{
          textAlign: "center",
          fontWeight: "bold",
          margin: "30px 0 10px 0",
        }}
      >
        الصادرة والواردة
      </Typography>
      <Box sx={{ width: "100%", margin: "20px", direction: "rtl" }}>
        <Box sx={{ padding: "20px", direction: "rtl" }}>
          <Grid
            container
            spacing={2}
            sx={{
              justifyContent: "space-between", 
              }}
          >
            <Grid item xs={12} sm={6} md={4}>
              <Card
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "20px",
                  // backgroundColor: "#fff",
                  // color: "#000",
                  color: theme.palette.mode === "dark" ? "#44484e" : "#000",
                  backgroundColor:
                    theme.palette.mode === "dark" ? "#fff" : "#44484e",
                }}
              >
                <CardContent>
                  <Typography
                    variant="h5"
                    component="div"
                    gutterBottom
                    fontWeight={"bold"}
                    sx={{
                      color: theme.palette.mode === "dark" ? "#44484e" : "#fff",
                    }}
                  >
                    اضافة مصرف
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleOpenDialog("expense")}
                    sx={{
                      // background: "#44484e",
                      // color: "#fff",
                      fontWeight: "bold",
                      fontSize: "20px",
                      marginRight: "12px",
                      color: theme.palette.mode === "dark" ? "#fff" : "#44484e",
                      backgroundColor:
                        theme.palette.mode === "dark" ? "#44484e" : "#fff",

                      "&:hover": {
                        backgroundColor: "#5c6169",
                      },
                    }}
                  >
                    مصرف
                  </Button>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Card
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "20px",
                  // backgroundColor: "#fff",
                  // color: "#000",
                  color: theme.palette.mode === "dark" ? "#44484e" : "#000",
                  backgroundColor:
                    theme.palette.mode === "dark" ? "#fff" : "#44484e",
                }}
              >
                <CardContent>
                  <Typography
                    variant="h5"
                    component="div"
                    gutterBottom
                    fontWeight={"bold"}
                    sx={{
                      color: theme.palette.mode === "dark" ? "#44484e" : "#fff",
                    }}
                  >
                    اضافة دخل
                  </Typography>
                  <Button
                    variant="contained"
                    onClick={() => handleOpenDialog("income")}
                    sx={{
                      // background: "#44484e",
                      // color: "#fff",
                      fontWeight: "bold",
                      fontSize: "20px",
                      marginRight: "12px",
                      color: theme.palette.mode === "dark" ? "#fff" : "#44484e",
                      backgroundColor:
                        theme.palette.mode === "dark" ? "#44484e" : "#fff",

                      "&:hover": {
                        backgroundColor: "#5c6169",
                      },
                    }}
                  >
                    دخل
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>

        <ExpensesTable />
        <IncomeTable />
      </Box>
      <DialogWrapper
        open={openDialog === "expense"}
        onClose={handleCloseDialog}
        // title="إضافة دين"
        component={<CreateExpenses />}
      />
      <DialogWrapper
        open={openDialog === "income"}
        onClose={handleCloseDialog}
        // title="إضافة تسديد"
        component={<CreateIncome />}
      />
    </Container>
  );
};

export default AllExpensesIncome;
